import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {NavigationService} from '../../services/navigation.service';
import {ActivatedRoute} from '@angular/router';
import {getLogingBG, getLogingBGBottomLeft, getLogo, getLogoDark} from '../../helpers/assets.helper';
import {trigger, transition, animate, style} from '@angular/animations';

@Component({
  selector: 'fa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    trigger('fade', [
      transition('void => *', [style({opacity: 0}), animate('300ms', style({opacity: 1}))]),
      transition('* => void', [style({opacity: 1}), animate('300ms', style({opacity: 0}))]),
    ])
  ]
})
export class LoginComponent implements OnInit, OnDestroy {
  current = 0;
  img_list = [
    {
      title: 'Track Your Statistics',
      img: 'assets/images/slider1.png'
    },
    {
      title: 'Manage Your Courses',
      img: 'assets/images/slider2.png'
    },
    {
      title: 'Unlock Your Student Levels',
      img: 'assets/images/slider3.png'
    }
  ];
  logo: string = getLogoDark();
  logoWhite: string = getLogo();
  loginBG: string = getLogingBG();
  loginBGBottom: string = getLogingBGBottomLeft();
  email: string = '';
  password: string = '';
  rememberMe: boolean = false;
  error: string = null;
  private routeSubscription: Subscription = null;

  constructor(private auth: AuthService, private route: ActivatedRoute, private navigation: NavigationService) {
  }

  ngOnInit() {
    this.routeSubscription = this.route.queryParams.subscribe((params: any) => {
      //console.log(params);
      if (params.email) {
        this.email = params.email;
      }
      if (params.password) {
        this.password = params.password;
      }
    });
    //
    setInterval(() => {
      this.current = ++this.current % this.img_list.length;
    }, 5000);
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  onSubmit() {
    this.auth.signIn(this.email, this.password, this.rememberMe).then(() => {
      this.navigation.redirectTo('dashboard');
    }).catch((error: Error) => {
      this.error = error.message;
    });
  }
  onRegister() {
    this.navigation.redirectTo('register');
  }

  dismissError(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.error = null;
  }

}
