import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';


const routes: Routes = [
  {
    path: 'admin',
    // loadChildren: () => import('fire-admin').then(m => m.FireAdminModule)
    loadChildren: () => import('projects/fire-admin/src/public-api').then(m => m.FireAdminModule)
  },
  {
    path: '**',
    redirectTo: 'admin/dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
