
export const fr = {
  // Login page
  SignInToYourAccount: 'Veuillez vous connecter à votre compte',
  EmailAddress: 'Adresse mail',
  EnterEmail: 'Entrez votre e-mail',
  Password: 'Mot de passe',
  RememberMe: 'Se souvenir de moi',
  SignIn: 'Se connecter',
  ForgotYourPassword: 'Mot de passe oublié?',
  // Register page
  WelcomeToFireAdmin: 'Bienvenue dans FireAdmin',
  RepeatPassword: 'Répéter le mot de passe',
  CreateAccount: 'Créer un compte',
  // Sidebar
  Dashboard: 'Tableau de bord',
  Pages: 'Pages',
  List: 'Liste',
  Add: 'Ajouter',
  Posts: 'Articles',
  Categories: 'Catégories',
  Translations: 'Traductions',
  Comments: 'Commentaires',
  Menus: 'Menus',
  Media: 'Médias',
  Users: 'Utilisateurs',
  // Navbar
  AddPost: 'Ajouter un article',
  Profile: 'Profil',
  Settings: 'Réglages',
  Logout: 'Se déconnecter',
  // Dashboard
  PostsByStatus: 'Articles par statut',
  // Pages: Add
  AddNewPage: 'Ajouter une nouvelle page',
  PageTitle: 'Titre de la page',
  PageSlug: 'Slug',
  PageLanguage: 'Langue',
  PageBlock: 'Bloc',
  PageBlocks: 'Blocs',
  AddPage: 'Ajouter la page',
  AddBlock: 'Ajouter un bloc',
  BlockName: 'Nom',
  BlockType: 'Type',
  BlockContent: 'Contenu',
  RemoveBlock: 'Retirer',
  CollapseBlock: 'Ranger',
  PageAdded: 'Page ajoutée avec succès!',
  PageSlugAlreadyExists: 'Le slug de la page existe déjà! Veuillez en choisir un autre.',
  // Pages: Block types
  Text: 'Text',
  HTML: 'HTML',
  JSON: 'JSON',
  // Pages: Edit
  EditPage: 'Modifier la page',
  PageSaved: 'Page enregistrée avec succès!',
  // Pages: Translate
  TranslatePage: 'Traduire la page',
  // Pages: List
  PageCreatedAt: 'Date de création',
  PageUpdatedAt: 'Date de la dernière modification',
  PageAuthor: 'Auteur',
  DeletePage: 'Supprimer la page',
  ConfirmDeletePage: 'Veuillez confirmer la suppression de la page <b>${title}</b> ?',
  PageDeleted: 'Page <b>${title}</b> supprimée!',
  // Posts: Add
  AddNewPost: 'Ajouter un nouvel article',
  PostTitle: 'Titre de l\'article',
  PostContent: 'Contenu de l\'article',
  Actions: 'Actions',
  PostLanguage: 'Langue',
  PostStatus: 'Statut',
  PostDate: 'Date',
  PostImage: 'Image',
  PostSlug: 'Slug',
  SaveDraft: 'Enregistrer le brouillon',
  Publish: 'Publier',
  MoveToTrash: 'Mettre à la corbeille',
  PostAdded: 'Article ajouté avec succès!',
  PostSlugAlreadyExists: 'Le slug de l\'article existe déjà! Veuillez en choisir un autre.',
  // Posts: Status
  Draft: 'Brouillon',
  Published: 'Publié',
  Trash: 'Corbeille',
  // Posts: List
  PostCreatedAt: 'Date de création',
  PostUpdatedAt: 'Date de la dernière modification',
  PostAuthor: 'Auteur',
  Translate: 'Traduire',
  DeletePost: 'Supprimer l\'article',
  ConfirmDeletePost: 'Veuillez confirmer la suppression de l\'article <b>${title}</b> ?',
  PostDeleted: 'Article <b>${title}</b> supprimé!',
  // Posts: Edit
  EditPost: 'Modifier l\'article',
  PostSaved: 'Article enregistré avec succès!',
  // Posts: Translate
  TranslatePost: 'Traduire l\'article',
  // Languages
  All: 'Tout',
  English: 'Anglais',
  French: 'Français',
  Arabic: 'Arabe',
  // Settings
  General: 'Général',
  Internationalization: 'Internationalisation',
  Language: 'Langue',
  SidebarStyle: 'Style de barre latérale',
  Expanded: 'Étendu',
  Collapsed: 'Rangé',
  HeaderBar: 'Barre d\'en-tête',
  SaveChanges: 'Sauvegarder les modifications',
  SettingsSaved : 'Paramètres enregistrés avec succès!',
  SupportedLanguages : 'Langues prises en charge',
  Key: 'Clé',
  Remove: 'Supprimer',
  Cancel: 'Annuler',
  // Categories
  NewCategory: 'Nouvelle catégorie',
  CategoryLabel: 'Libellé',
  CategorySlug: 'Slug',
  CategoryLanguage: 'Langue',
  AddCategory: 'Ajouter une catégorie',
  CategoryAdded: 'Catégorie ajoutée avec succès!',
  DeleteCategory: 'Supprimer la catégorie',
  ConfirmDeleteCategory: 'Veuillez confirmer la suppression de la catégorie <b>${label}</b> ?',
  CategoryDeleted: 'Catégorie <b>${label}</b> supprimée!',
  EditCategory: 'Modifier la catégorie',
  CategorySaved: 'Catégorie <b>${label}</b> enregistrée!',
  // Lists
  Edit: 'Modifier',
  Delete: 'Supprimer',
  Save: 'Enregistrer',
  // Users: Add
  AddNewUser: 'Ajouter un nouvel utilisateur',
  AddUser: 'Ajouter un utilisateur',
  NewUser: 'Nouvel utilisateur',
  FirstName: 'Prénom',
  LastName: 'Nom de famille',
  Email: 'Email',
  BirthDate: 'Date de naissance',
  Role: 'Rôle',
  Bio: 'Bio',
  BioPlaceholder: 'Je suis un ingénieur spécialisé dans la conception.',
  ProfilePicture: 'Photo de profil',
  UploadImage: 'Télécharger une image',
  UserAdded: 'Utilisateur ajouté avec succès!',
  // Users: Edit
  EditUser: 'Modifier l\'utilisateur',
  UserDetails: 'Détails de l\'utilisateur',
  UpdateUser: 'Mettre à jour l\'utilisateur',
  UserUpdated: 'Utilisateur mis à jour avec succès!',
  // Users: Profile
  UserProfile: 'Profil de l\'utilisateur',
  LatestPosts: 'Derniers articles',
  FullList: 'Liste complète',
  // Users: List
  UserName: 'Nom d\'utilisateur',
  CreatedAt: 'Date de création',
  UpdatedAt: 'Date de la dernière modification',
  CreatedBy: 'Créé par',
  DeleteUser: 'Supprimer l\'utilisateur',
  ConfirmDeleteUser: 'Veuillez confirmer la suppression de l\'utilisateur <b>${name}</b> ?',
  UserDeleted: 'Utilisateur <b>${name}</b> supprimé!',
  // Users: Roles
  Administrator: 'Administrateur',
  Editor: 'Éditeur',
  Author: 'Auteur',
  Contributor: 'Contributeur',
  Guest: 'Invité',
  // Translations
  NewTranslation: 'Nouvelle traduction',
  TranslationKey: 'Clé',
  TranslationValue: 'Valeur',
  TranslationLanguage: 'Langue',
  AddTranslation: 'Ajouter une traduction',
  TranslationAdded: 'Traduction ajoutée avec succès!',
  DeleteTranslation: 'Supprimer la traduction',
  ConfirmDeleteTranslation: 'Veuillez confirmer la suppression de la traduction <b>${key}</b> ?',
  TranslationDeleted: 'Traduction <b>${key}</b> supprimée!',
  EditTranslation: 'Modifier la traduction',
  TranslationSaved: 'Traduction <b>${key}</b> enregistrée!',
  TranslationKeyAlreadyExists: 'La clé de traduction <b>${key}</b> existe déjà pour la langue <b>${lang}</b>.',
  TranslationsTooltip: 'Les traductions peuvent être utilisées avec la bibiliothèque <a href="http://www.ngx-translate.com/" target="_blank">ngx-translate</a>, pour tout texte répétitif (en-tête, pied de page ou fil d\'Ariane) à traduire.',
  // Error messages
  GuestsAreNotAllowedToPerformChanges: 'Les invités ne sont pas autorisés à effectuer des modifications.',
};
