
export const en = {
  // Login page
  SignInToYourAccount: 'Language Application',
  EmailAddress: 'Email address',
  EnterEmail: 'Enter email',
  Password: 'Password',
  RememberMe: 'Remember me',
  SignIn: 'Sign In',
  ForgotYourPassword: 'Forgot your password?',
  // Register page
  WelcomeToFireAdmin: 'Welcome To SpeakEasy',
  RepeatPassword: 'Repeat Password',
  CreateAccount: 'Create Account',
  // Sidebar
  Dashboard: 'Dashboard',
  Pages: 'Pages',
  List: 'List',
  Add: 'Add',
  Courses: 'Courses',
  Categories: 'Categories',
  Translations: 'Translations',
  Comments: 'Comments',
  Menus: 'Menus',
  Media: 'Media',
  Users: 'Users',
  // Navbar
  AddPost: 'Add post',
  Profile: 'Profile',
  Settings: 'Settings',
  Logout: 'Logout',
  // Dashboard
  PostsByStatus: 'Courses By Status',
  // Pages: Add
  AddNewPage: 'Add New Page',
  PageTitle: 'Page Title',
  PageSlug: 'Slug',
  PageLanguage: 'Language',
  PageBlock: 'Block',
  PageBlocks: 'Blocks',
  AddPage: 'Add Page',
  AddBlock: 'Add Block',
  BlockName: 'Name',
  BlockType: 'Type',
  BlockContent: 'Content',
  RemoveBlock: 'Remove',
  CollapseBlock: 'Collapse',
  PageAdded: 'Page successfully added!',
  PageSlugAlreadyExists: 'Page slug already exists! Choose another one.',
  // Pages: Block types
  Text: 'Text',
  HTML: 'HTML',
  JSON: 'JSON',
  // Pages: Edit
  EditPage: 'Edit Page',
  PageSaved: 'Page successfully saved!',
  // Pages: Translate
  TranslatePage: 'Translate Page',
  // Pages: List
  PageCreatedAt: 'Creation date',
  PageUpdatedAt: 'Last Modification date',
  PageAuthor: 'Author',
  DeletePage: 'Delete Page',
  ConfirmDeletePage: 'Please confirm deleting page <b>${title}</b> ?',
  PageDeleted: 'Page <b>${title}</b> has been deleted!',
  // Posts: Add
  AddNewPost: 'Add New Course',
  PostTitle: 'Course Title',
  PostContent: 'Course Description',
  Actions: 'Actions',
  PostLanguage: 'Language',
  PostStatus: 'Status',
  PostDate: 'Date',
  PostImage: 'Image',
  PostSlug: 'Slug',
  SaveDraft: 'Save Draft',
  Publish: 'Publish',
  MoveToTrash: 'Move To Trash',
  PostAdded: 'Post successfully added!',
  PostSlugAlreadyExists: 'Post slug already exists! Choose another one.',
  // Posts: Status
  Draft: 'Draft',
  Published: 'Published',
  Trash: 'Trash',
  // Posts: List
  PostCreatedAt: 'Creation date',
  PostUpdatedAt: 'Last Modification date',
  PostAuthor: 'Author',
  Translate: 'Translate',
  DeletePost: 'Are you sure?',
  ConfirmDeletePost: 'Would you like to permanently delete this <b>${title}</b> ?',
  PostDeleted: 'Course <b>${title}</b> has been deleted!',
  // Posts: Edit
  EditPost: 'Edit Course',
  PostSaved: 'Course successfully saved!',
  // Posts: Translate
  TranslatePost: 'Translate Post',
  // Languages
  All: 'All',
  English: 'English',
  French: 'French',
  Arabic: 'Arabic',
  // Settings
  General: 'General',
  Internationalization: 'Internationalization',
  Language: 'Language',
  SidebarStyle: 'Sidebar style',
  Expanded: 'Expanded',
  Collapsed: 'Collapsed',
  HeaderBar: 'Header bar',
  SaveChanges: 'Save Changes',
  SettingsSaved : 'Settings successfully saved!',
  SupportedLanguages : 'Supported languages',
  Key: 'Key',
  Remove: 'Remove',
  Cancel: 'Cancel',
  // Categories
  NewCategory: 'New Category',
  CategoryLabel: 'Label',
  CategorySlug: 'Slug',
  CategoryLanguage: 'Language',
  AddCategory: 'Add Category',
  CategoryAdded: 'Category successfully added!',
  DeleteCategory: 'Delete Category',
  ConfirmDeleteCategory: 'Please confirm deleting category <b>${label}</b> ?',
  CategoryDeleted: 'Category <b>${label}</b> has been deleted!',
  EditCategory: 'Edit Category',
  CategorySaved: 'Category <b>${label}</b> has been saved!',
  // Lists
  Edit: 'Edit',
  Delete: 'Delete',
  Save: 'Save',
  // Users: Add
  AddNewUser: 'Add New User',
  AddUser: 'Add User',
  NewUser: 'New User',
  FirstName: 'First Name',
  LastName: 'Last Name',
  Email: 'Email',
  BirthDate: 'Birth Date',
  Role: 'Role',
  Bio: 'Bio',
  BioPlaceholder: 'I\'m a design focused engineer.',
  ProfilePicture: 'Profile Picture',
  UploadImage: 'Upload Image',
  UserAdded: 'User successfully added!',
  // Users: Edit
  EditUser: 'Edit User',
  UserDetails: 'User Details',
  UpdateUser: 'Update User',
  UserUpdated: 'User successfully updated!',
  // Users: Profile
  UserProfile: 'User Profile',
  LatestPosts: 'Latest Posts',
  FullList: 'Full List',
  // Users: List
  UserName: 'User name',
  CreatedAt: 'Creation date',
  UpdatedAt: 'Last modification date',
  CreatedBy: 'Created by',
  DeleteUser: 'Delete User',
  ConfirmDeleteUser: 'Please confirm deleting user <b>${name}</b> ?',
  UserDeleted: 'User <b>${name}</b> has been deleted!',
  // Users: Roles
  Administrator: 'Administrator',
  Editor: 'Editor',
  Author: 'Author',
  Contributor: 'Contributor',
  Guest: 'Guest',
  // Translations
  NewTranslation: 'New Translation',
  TranslationKey: 'Key',
  TranslationValue: 'Value',
  TranslationLanguage: 'Language',
  AddTranslation: 'Add Translation',
  TranslationAdded: 'Translation successfully added!',
  DeleteTranslation: 'Delete Translation',
  ConfirmDeleteTranslation: 'Please confirm deleting translation <b>${key}</b> ?',
  TranslationDeleted: 'Translation <b>${key}</b> has been deleted!',
  EditTranslation: 'Edit Translation',
  TranslationSaved: 'Translation <b>${key}</b> has been saved!',
  TranslationKeyAlreadyExists: 'Translation key <b>${key}</b> already exists for language <b>${lang}</b>.',
  TranslationsTooltip: 'Translations can be used with <a href="http://www.ngx-translate.com/" target="_blank">ngx-translate</a> library, for any repetitive text (on header/footer or breadcrumb) that needs to be translated.',
  // Error messages
  GuestsAreNotAllowedToPerformChanges: 'Guest users are not allowed to perform changes.',
};
