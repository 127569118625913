declare var Chart: any;

export function initPieChart(selector: string, data: any[], labels: any[], backgroundColor: string[] = [], hoverBorderColor: string = '#ffffff') {
  const canvas = document.querySelector(selector);
  if (backgroundColor.length === 0) {
    backgroundColor = [
      'rgba(0,123,255,0.9)',
      'rgba(0,123,255,0.5)',
      'rgba(0,123,255,0.3)'
    ];
  }
  const chart = new Chart(canvas, {
    type: 'doughnut',
    data: {
      datasets: [
        {
          hoverBorderColor,
          data,
          backgroundColor
        }
      ],
      labels
    },
    options: {
      responsive: true,
      legend: { position: 'top', labels: { padding: 25, boxWidth: 20 } },
      cutoutPercentage: 0,
      tooltips: { custom: !1, mode: 'index', position: 'nearest' }
    }
  });

  return chart;
}

export function initDoughChart(selector: string) {
  const canvas = document.querySelector(selector);
  const data = {
    datasets: [{
      data: [300, 50, 100],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)'
      ],
      hoverOffset: 4
    }]
  };
  const chart = new Chart(canvas, {
    type: 'doughnut',
    data
  });

  return chart;
}
